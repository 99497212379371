.tpin-modal-container{
    border-radius: 12px;
    padding: 38px 25%;
}

.get-otp-btn {
    position: absolute;
    left: 0%;
    bottom: -10%;
}

.eye-password{
    position: absolute;
    cursor: pointer;
    margin:6px -2rem;
}

.t-pin-title{
    font-weight: 700;
    font-size: 14px;
    color: #112467;
}

.resend-otp{
    width: 100px;
    cursor: pointer;
    margin: 0 auto;
    border-bottom:1px solid;
}