.my-ledger-container {
    margin: -16px 0 0 -16px !important;
  }
  
  .my-ledger-box {
    background: rgba(255, 255, 255, 0.75);
    box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(13.5914px);
    -webkit-backdrop-filter: blur(13.5914px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 12px;
    /* padding-top: 3px; */
    /* padding-bottom: 19px; */
  }
  
  /* input[type="date"]::-webkit-calendar-picker-indicator {
      background: transparent;
      bottom: 0;
      color: transparent;
      cursor: pointer;
      height: auto;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
  } */
  
  .myLedger-inputfield {
    background: linear-gradient(91.91deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.4) 100.82%);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    width: 100%;
  }
  
  .myLedger-inputfield::placeholder {
    font-family: "Libre Franklin";
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #8392ab;
  }
  
  .ledger-btn {
    position: absolute;
    left: 0%;
    bottom: -24px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .myledger-modal-container {
    border-radius: 12px;
    padding: 40px 40px 60px 40px;
  }
  #myledger-showmore-btn {
    padding: 40px 0;
  }
  
  .tab-title {
    color: #112467;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    user-select: none;
    cursor: pointer;
    text-align: center;
  }
  .tab-title-bold {
    font-weight: 700;
    font-size: 14px;
    color: #112467;
  }
  .tab-title-border {
    position: relative;
    display: inline-block;
  }
  
  .tab-title-border::before {
    content: "";
    position: absolute;
    background-color: #112467;
    width: 100%;
    height: 4px;
    border-radius: 6px;
    bottom: -10px;
    left: 0;
    right: 0;
  }
  
  .report-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .report-title-container .report-title {
    font-family: Libre Franklin;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    color: #212121;
  }
  
  .report-button-conatiner {
    gap: 10px;
    display: inline-flex;
  }
  