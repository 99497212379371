#createRetailer-form-container {
    border-radius: 12px;
    padding: 30px 30px 80px 30px;
    /* margin: 20px; */
}

#createRetailer-form-container>div {
    gap: 10%;
}

#createRetailer-form-title {
    font-family: Libre Franklin;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-align: left;
    color: rgba(17, 36, 103, 1);
}

.createRetailer-form div label {
    font-family: Libre Franklin;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 1px;
    text-align: left;
    color: #000000;
}

.createRetailer-form {
    width: 100%;
}

.createRetailer-form>div {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.createRetailer-form-submitbtn {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: -24px;
    left: 0%;
    width: 100%;
}