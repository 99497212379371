main {
    width: 100%;
    min-height: 100vh;
}

.sidebar {
    position: fixed;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    background: #FFFFFF80;
}

.sidebarclosed-openicon {
    display: flex;
    font-size: 30px;
    margin: 20px auto 10px auto;
    display: flex;
    justify-content: center;
    transform: rotate(180deg);
}

.top_section {
    display: flex;
    align-items: center;
    padding: 20px 15px;
}

.logo {
    font-size: 30px;
}

.sidebarclosed-container{
    /* overflow-y-scroll overflow-auto h-full  */
    height: calc(100% - 44px);
    height: 100%;
    overflow-y: scroll;
}

.navlink {
    display: flex;
    margin: 10px 10px;
    gap: 15px;
    transition: all 0.5s;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.02em;
    align-items: center;
    color: #67748E;
}

.navlink:hover {
    background-color: #ffffff;
    /* box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05); */
    box-shadow: 0 0 0.5px 0.5px #112467;
    border-radius: 8px;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #112467;
    transition: all 0.5s;
}

#sidebarclosed-navlink-container .active {
    background: #FFFFFF;
    /* box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05); */
    box-shadow: 0 0 1px 1px #112467;
    border-radius: 8px;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.02em;
    align-items: center;
    color: #112467;
}

#sidebarclosed-navlink-container .active .icon svg {
    fill: white;
    background: #112467;
    border-radius: 10px;
}

.icon {
    background: #FFFFFF;
    fill: #112467;
    border-radius: 10px;
}

.icon,
.link_text {
    font-size: 14px;
}

a{
    height: 48px;
}

.sidebarclosed-container::-webkit-scrollbar {
    width: 5px;
    /* padding: 10px 0; */
    /* padding-top: 16px; */
    /* scroll-padding: 16px 0; */
  }

.sidebarclosed-container::-webkit-scrollbar-track
{
    margin: 26px 0;
}