.pagenotfound-container{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.pagenotfound-content h1{
    font-size: 100px;
    font-weight: 900;
    color: #112467;
}

.pagenotfound-content p{
    font-size: 20px;
    font-weight: 500;
    color: #8392AB;
}

.pagenotfound-btncontiner{
    margin: 40px 0px;
    display: flex;
    justify-content: center;
    width: 100%;
}

.pagenotfound-btncontiner .btn-goback{
    margin: 0px 10px;
    width: 25%;
}

.pagenotfound-btncontiner .btn-home{
    margin: 0px 10px;
    width: 25%;
}

.pagenotfound-image{
    height: 100%;
    width: 25%;
    padding-top: 50px;
}

.pagenotfound-image svg{
    fill: #112467;
    height: 100%;
    width: 100%;
}