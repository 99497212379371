.custom-report-table-container {
  border-radius: 10px;
  overflow-x: scroll;
}

/* .custom-report-table-container::-webkit-scrollbar {
  display: none;
} */

.custom-report-table-container table {
  width: 100%;
  overflow: scroll;
  border-spacing: 0;
  border-collapse: separate;
}

/* header Styling */
.custom-report-table-container th {
  background-color: #112467;
  color: white;
  padding: 5px;
  font-family: Libre Franklin;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0px;
  text-align: left;
  min-width: 150px;
  border-right: 1px solid #dddddd;
  white-space: nowrap;
  height: 40px;
}

/* Sorting Icon container */
.report-table-header-icon-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.custom-report-table-container th:last-child {
  border-right: none;
}

/* header fixed first 3 */
/* .custom-report-table-container th:nth-child(1) {
  position: sticky;
  min-width: 200px;
  max-width: 200px;
  left: 0;
  z-index: 50;
}
.custom-report-table-container th:nth-child(2) {
  position: sticky;
  min-width: 200px;
  max-width: 200px;
  left: 200px;
  z-index: 50;
}
.custom-report-table-container th:nth-child(3) {
  position: sticky;
  min-width: 150px;
  max-width: 150px;
  left: 400px;
  z-index: 50;
} */

/* cell styling */
.custom-report-table-container td {
  color: #212121;
  padding: 5px;
  font-family: Libre Franklin;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  white-space: nowrap;
  /* max-width: 250px; */
  text-overflow: ellipsis;
  overflow: hidden;
}

/* row cell fixed first 3 */
/* .custom-report-table-container td:nth-child(1) {
  position: sticky;
  min-width: 200px;
  max-width: 200px;
  background-color: inherit;
  left: 0;
  border-left: 1px solid #dddddd;
}
.custom-report-table-container td:nth-child(2) {
  position: sticky;
  min-width: 200px;
  max-width: 200px;
  background-color: inherit;
  left: 200px;
}
.custom-report-table-container td:nth-child(3) {
  position: sticky;
  min-width:200px;
  max-width: 200px;
  background-color: inherit;
  left: 400px;
} */

/* stripe color row */

.custom-report-table-container tbody tr:nth-child(2n + 1) {
  background-color: #ffffff;
}

.custom-report-table-container tbody tr:nth-child(2n) {
  background-color: #f5f5f5;
}

/* Pagination Styling */

.report-pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.report-pagination-container .pagination-label {
  font-family: Libre Franklin;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
}

.report-pagination-container .pagination-limit-selector {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 3px;
  padding: 5px;
  font-family: Libre Franklin;
  font-size: 14px;
  font-weight: 400;
  border-radius: 8px;
  width: 85px;
  outline: none;
  margin: 0 10px;
  border-right: 16px solid transparent;
}

.report-pagination-container .report-paginator-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.report-pagination-container .report-paginator-container span {
  font-family: Libre Franklin;
  font-size: 14px;
  font-weight: 400;
}

/* No data container */

.report-nodata-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -90px;
}

.report-nodata-container .report-nodata-image {
  height: 200px;
  width: 200px;
}

.report-nodata-container .report-nodata-title {
  color: #112467;
}


/* Sort Icon Styling */
.report-table-header-icon{
  opacity: 0;
}

.report-table-header-icon-container:hover .report-table-header-icon{
  opacity: 1 !important;
}
