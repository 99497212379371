.custom-btn {
  background-color: #112467;
  color: rgba(212, 176, 81, 1);
  border-radius: 8px;
  height: 48px;
  font-size: 14px;
  font-weight: 700;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  /* box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%); */
}

.custom-btn:hover {
  box-shadow: rgba(17, 12, 46, 0.15) 0px 5px 5px 0px;
}
/* 
.custom-btn:active {
    opacity: 0.90;
} */

.export-btn {
  border-radius: 8px;
  height: 40px;
  border: 2px solid #112467;
}

.report-btn {
  border-radius: 8px;
  height: 40px;
  border: 2px solid #112467;
  color: black;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
}
