.buykits-modal {
    border-radius: 12px;
    padding: 38px 30px 60px 30px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.buykits-btn {
    padding: 0 25%;
}

.buykits-modal-btn{
    position: absolute;
    bottom: -10%;
    width: 100%;
    left: 0%;
    display: flex;
    justify-content: center;
}

.buykits-modal-table {
    /* box-shadow: 0px 20px 27px 0px #0000000D;
    backdrop-filter: blur(13.5914px);
    -webkit-backdrop-filter: blur(13.5914px);
    background: rgba(255, 255, 255, 0.75); */
    background-color: white;
    padding: 20px;
    position: relative;
}

.kit-mgnt{
    font-weight: 700;
    font-size: 14px;
    color: #112467;
}