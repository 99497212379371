.copyright {
    /* display: flex;
    align-items: center;
    flex-direction: column; */
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
}

.copyright>div {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
}