@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Libre Franklin', sans-serif;
}

body {
  background-color: #f3f6f9;
}

.errorText {
  font-size: 12px !important;
  color: red !important;
}

.modal-transparency {
  /* opacity: .75; */
  /* box-shadow: 0px 20px 27px 0px rgba(0, 0, 0, 0.05);
    background: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(13.5914px);
    -webkit-backdrop-filter: blur(13.5914px); */
  box-shadow: 0px 20px 27px 0px rgb(0 0 0 / 7%);
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(1.5914px);
  -webkit-backdrop-filter: blur(1.5914px);
}

.copyright-text {
  font-family: Libre Franklin;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
}

.scrolling-text-container {
  /* width: 100%; */
  /* Set the width of the container */
  overflow: hidden;
  /* Hide the content exceeding the container */
  white-space: nowrap;
  /* Prevent text from wrapping */
}

.scrolling-text {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  /* Ensure the text scrolls horizontally */
  animation: scrollText 20s linear infinite;
  /* Define animation */
}

@keyframes scrollText {
  from {
    transform: translateX(100%);
    /* Start offscreen from right */
  }

  to {
    transform: translateX(-100%);
    /* Scroll to offscreen left */
  }
}

.business-summary-container > .business-summary:last-child {
  border-bottom-width: 0;
}

.custom-scrollbar::-webkit-scrollbar {
  height: 8px;
  width: 4px;
  scroll-behavior: smooth;
}

.custom-scrollbar::-webkit-scrollbar-track {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  /* overflow: hidden; */
  margin: 12px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 12px !important;
  background-color: #112467 !important;
}
