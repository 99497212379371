#payment-virtual-modal {
    border-radius: 12px;
    padding: 0 30px;
}

#payment-virtual-modal-title {
    padding-top: 38px;
    font-weight: 500;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #112467;
}