

/*  Card styling start */
/* #card-container {
    gap: 24px;
} */

#item {
    /* className="rounded-2xl flex bg-white mx-3 w-2/5 justify-between mt-5" */
    /* style={{ boxShadow: "0px 8px 27px 0px rgba(0, 0, 0, 0.05)" }} */
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 8px 27px 0px rgba(0, 0, 0, 0.05);
    height: 182px;
    /* width: 565px; */
    border-radius: 16px;

}

.card-title1 {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    /* text-align: left; */
    padding-left: 29px;
    padding-top: 24px;
}

.card-title2 {
    padding-left: 29px;
    padding-top: 39px;
    padding-bottom: 65px;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
}

.card-sub-title2 {
    margin-left: 18px;
}



#card-left-image::after {
    /* height: 146px;
    width: 146px; */
    /* style={{ position: "relative", top: "-10%", left: "15%" }} */
    content: url("../../../assets/card-image.svg");
    position: relative;
    top: -5%;
    left: 10%;
    z-index: 1;
}