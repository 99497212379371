#fundload-container {
    border-radius: 12px;
    padding: 38px 30px 40px 30px;
}

#fund-balance {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #112467;
    margin-bottom: 20px;
}

.table-container {
    border-radius: 10px;
}